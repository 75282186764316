import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../service/customer.service';
import domain from './../../data/domain';
declare var $: any;
declare var DataTable: any;

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {
  public customers: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void {
    this.getCustomerList();
  }


  getCustomerList() {
    this.customerService.getAllData()
      .subscribe(
        res => {
          this.customers = res.data;
          this.pageLoading = 0;
        },
        err => {

        }
      )
  }

  initDatatable() {
    $('#bootstrap-data-table').DataTable();
  }

}
