import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from '../service/order.service';
import domain from '../../data/domain';

@Component({
  selector: 'app-order-show',
  templateUrl: './order-show.component.html',
  styleUrls: ['./order-show.component.css']
})
export class OrderShowComponent implements OnInit {
  public data: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private orderService: OrderService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getSubCategory();
  }


  getSubCategory() {
    let id = this.activatedRoute.snapshot.params.id;
    this.orderService.getDataById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.data = res.data;
      })
  }

  calSelectedProductsSum() {
    let selectedProductSum = 0;
    this.data.orderProducts.forEach((product) => {
      selectedProductSum = selectedProductSum + (product.discountPrice ?? product.price);
    });
    return selectedProductSum;
  }

}
