import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from '../service/project.service';
import domain from '../../data/domain';

@Component({
  selector: 'app-galleryImage-show',
  templateUrl: './galleryImage-show.component.html',
  styleUrls: ['./galleryImage-show.component.css']
})
export class GalleryImageShowComponent implements OnInit {
  public category: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private categoryService: ProjectService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getCategory();
  }


  getCategory() {
    let id = this.activatedRoute.snapshot.params.id;
    this.categoryService.getCategoryById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.category = res.data;
      })
  }

}
