import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {

  public category = {
    title: '',
    type: '',
    image: ''
  }
  public error: String;
  public pageLoading = 1;

  constructor(private categoryService: ProjectService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let id = this.activatedRoute.snapshot.params.id;
    this.categoryService.getCategoryById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.category.title = res.data.title;
        this.category.type = res.data.type;
      })
  }

  updateCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    let id = this.activatedRoute.snapshot.params.id;
    var fd = new FormData();
    fd.append('title', this.category.title);
    fd.append('type', this.category.type);
    fd.append('uploadedImage', this.category['uploadedImage']);

    this.categoryService.updateCategory(id, fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Category updated successfully.')
        this.router.navigate(['/projects']);
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

}
