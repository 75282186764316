import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/home-slider.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.css']
})
export class ClientCreateComponent implements OnInit {

  public data = {
    title: '',
    categoryUrlSlug: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  store(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('title', this.data.title);
    fd.append('categoryUrlSlug', this.data.categoryUrlSlug);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.store(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Client created successfully.')
        this.router.navigate(['/clients'])
      }, (err) => {
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
