import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/home-slider.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.css']
})
export class TeamCreateComponent implements OnInit {

  public data = {
    name: '',
    designation: '',
    details: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  store(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('name', this.data.name);
    fd.append('designation', this.data.designation);
    fd.append('details', this.data.details);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.store(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Team created successfully.')
        this.router.navigate(['/teams'])
      }, (err) => {
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
