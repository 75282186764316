import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import domain from '../../data/domain';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http: HttpClient) { }

  getAllCategory() {
    return this.http.get<any>(domain + "quotes");
  }

  storeCategory(data) {
    return this.http.post<any>(domain + "quotes", data);
  }

  getCategoryById(id) {
    return this.http.get(domain + 'quotes/' + id);
  }

  updateCategory(id, data) {
    return this.http.put<any>(domain + "quotes/" + id, data);
  }

  deleteCategory(id) {
    return this.http.delete(domain + 'quotes/' + id);
  }
}
