<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Catalogs</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/catalogs">Catalogs</a></li>
                            <li class="active">Create</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content">
    <div class="animated fadeIn">


        <form #dataForm="ngForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <strong>Catalog Create</strong> Forms
                        </div>
                        <div class="card-body card-block">
                            <div class="form-horizontal">

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="title"
                                            class=" form-control-label">Title</label></div>
                                    <div class="col-12 col-md-6">
                                        <input type="text" class="form-control" id="title" placeholder=""
                                            [(ngModel)]="data.title" name="title" #title="ngModel" required>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(title.untouched&&!dataForm.submitted)||dataForm.valid">Please
                                            enter title name</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="email-input" class=" form-control-label">Description</label></div>
                                    <div class="col-12 col-md-6">
                                        <input type="text" class="form-control" id="name" placeholder=""
                                            [(ngModel)]="data.categoryUrlSlug" name="categoryUrlSlug"
                                            #categoryUrlSlug="ngModel" required>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(categoryUrlSlug.untouched&&!dataForm.submitted)||dataForm.valid">Please
                                            enter Slug name</small>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input" class=" form-control-label">Pdf</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="image" name="uploadedImage" class="form-control-file"
                                            (change)="imageSelect($event)" required appMaxFileSize="160000000" ngModel
                                            #uploadedImage="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedImage.errors||!uploadedImage.errors.validFileSize">Please
                                            select image less than 160000 MB</small>
                                        <!-- <div *ngIf="!validateImageSize('uploadedImage',719945)">
                                                Name is required.
                                            </div> -->

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary btn-sm" (click)="store(dataForm.form.valid)">
                                <i class="fa fa-dot-circle-o"></i> Submit
                            </button>
                            <button type="reset" class="btn btn-danger btn-sm">
                                <i class="fa fa-ban"></i> Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </form>

    </div><!-- .animated -->
</div><!-- .content -->

<app-page-loader *ngIf="pageLoading"></app-page-loader>