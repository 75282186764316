import { Component, OnInit } from '@angular/core';
import { LoginService } from './login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginUserData: any = {};
  public error = '';

  constructor(private loginControl: LoginService, private _router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  loginUser(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.loginControl.loginUserPost(this.loginUserData)
      .subscribe(
        res => {
          localStorage.setItem('token', res.data.token);
          this._router.navigate(['/dashboard']);
        },
        err => {
          this.error = 'Email Or Password are not match';
        }
      )
  }

}
