<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Videos</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/gallery-videos">Videos</a></li>
                            <li class="active">Create</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content">
    <div class="animated fadeIn">


        <form #categoryForm="ngForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <strong>Video Create</strong> Forms
                        </div>
                        <div class="card-body card-block">
                            <div class="form-horizontal">

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="email-input"
                                            class=" form-control-label">Title</label></div>
                                    <div class="col-12 col-md-6">
                                        <input type="text" class="form-control" id="title" placeholder=""
                                            [(ngModel)]="category.title" name="title" #title="ngModel" required>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(title.untouched&&!categoryForm.submitted)||title.valid">Please
                                            enter title name</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="email-input" class=" form-control-label">Video
                                            url</label></div>
                                    <div class="col-12 col-md-6">
                                        <input type="text" class="form-control" id="videoUrl" placeholder=""
                                            [(ngModel)]="category.videoUrl" name="videoUrl" #videoUrl="ngModel"
                                            required>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(videoUrl.untouched&&!categoryForm.submitted)||videoUrl.valid">Please
                                            enter video Url</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary btn-sm"
                                (click)="storeCategory(categoryForm.form.valid)">
                                <i class="fa fa-dot-circle-o"></i> Submit
                            </button>
                            <button type="reset" class="btn btn-danger btn-sm">
                                <i class="fa fa-ban"></i> Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </form>

    </div><!-- .animated -->
</div><!-- .content -->

<app-page-loader *ngIf="pageLoading"></app-page-loader>