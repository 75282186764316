import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.css']
})
export class AddNewComponent implements OnInit {
  @Input('link') public link;

  constructor() { }

  ngOnInit(): void {
  }

}
