import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import domain from '../../data/domain';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getAllData() {
    return this.http.get<any>(domain + "orders");
  }

  getDataById(id) {
    return this.http.get(domain + 'orders/' + id);
  }

  deleteData(id) {
    return this.http.delete(domain + 'orders/' + id);
  }
}
