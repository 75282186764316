import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galleryVideo-create',
  templateUrl: './galleryVideo-create.component.html',
  styleUrls: ['./galleryVideo-create.component.css']
})
export class GalleryVideoCreateComponent implements OnInit {

  public category = {
    title: '',
    videoUrl: ''
  }
  public error: String;
  public pageLoading = 0;

  constructor(private categoryService: ProjectService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  storeCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;

    this.categoryService.storeCategory(this.category)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Category created successfully.')
        this.router.navigate(['/gallery-videos'])
      }, (err) => {
        console.log(err)
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

}
