import { Injectable} from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(){}
  intercept(req, next) {
    let token = localStorage.getItem('token');
    if(!token) token='';
    let tokenizedReq = req.clone(
      {
        headers: req.headers.set('token', token)//add bearer for ignor length error,  undefined / null header not allow or use empty token
      }
    )
    return next.handle(tokenizedReq)
  }

}