import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-galleryImage-create',
  templateUrl: './galleryImage-create.component.html',
  styleUrls: ['./galleryImage-create.component.css']
})
export class GalleryImageCreateComponent implements OnInit {

  public category = {
    title: '',
    type: '',
    description: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;
  public Editor = ClassicEditor;

  constructor(private categoryService: ProjectService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  storeCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('title', this.category.title);
    fd.append('type', this.category.type);
    fd.append('description', this.category.description);
    fd.append('uploadedImage', this.category['uploadedImage']);
    if (this.category['uploadedImages'])
      for (let i = 0; i < this.category['uploadedImages'].length; i++) {
        fd.append("uploadedImages", this.category['uploadedImages'][i]);
      }

    this.categoryService.storeCategory(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Gallery created successfully.')
        this.router.navigate(['/gallery-images'])
      }, (err) => {
        console.log(err)
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

  multiImageSelect(event) {
    this.category[event.target.name] = event.target.files;
  }



}
