import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import domain from '../../data/domain';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: HttpClient) { }

  getAllData() {
    return this.http.get<any>(domain + "users");
  }
}
