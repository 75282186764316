import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/blog.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import domain from './../../data/domain';
declare var $: any;
declare var DataTable: any;

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {
  public sliders: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private homeSliderService: HomeSliderService, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getSliderList();
  }


  getSliderList() {
    this.homeSliderService.getAllData()
      .subscribe(
        res => {
          this.sliders = res.data;
          this.pageLoading = 0;
        },
        err => {

        }
      )
  }

  delete(id) {
    this.sweetalert.delete().then(res => {
      this.homeSliderService.delete(id)
        .subscribe((res) => {
          this.sweetalert.centerSuccessTimer('Blog successfully deleted.');
          this.getSliderList();
        }, (err) => {

        })
    }).catch(function (error) {
      // console.log(error);
    });
  }

  initDatatable() {
    $('#bootstrap-data-table').DataTable();
  }

}
