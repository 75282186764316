import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptorService } from './modules/interceptor/token-interceptor.service';
import { AppComponent } from './app.component';
import { ModulesModule } from './modules/modules.module'
import { LoginComponent } from './login/login.component';
// import { MaxFileSizeDirective } from './directives/max-file-size.directive';
// import { PageLoaderComponent } from './loader/page-loader/page-loader.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    // MaxFileSizeDirective,
    // PageLoaderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ModulesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
