import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeSliderService } from '../service/home-slider.service';
import domain from './../../data/domain';

@Component({
  selector: 'app-client-show',
  templateUrl: './client-show.component.html',
  styleUrls: ['./client-show.component.css']
})
export class ClientShowComponent implements OnInit {
  public data: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private homeSliderService: HomeSliderService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getData();
  }


  getData() {
    let id = this.activatedRoute.snapshot.params.id;
    this.homeSliderService.getDataById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.data = res.data;
      })
  }

}
