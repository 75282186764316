import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import domain from '../../data/domain';
declare var $: any;
declare var DataTable: any;

@Component({
  selector: 'app-galleryImage-list',
  templateUrl: './galleryImage-list.component.html',
  styleUrls: ['./galleryImage-list.component.css']
})
export class GalleryImageListComponent implements OnInit {
  public categories: any;
  public domain = domain;
  public pageLoading = 1;

  constructor(private categoryService: ProjectService, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getCategoryList();
  }


  getCategoryList() {
    this.categoryService.getAllCategory()
      .subscribe(
        res => {
          this.categories = res.data;
          this.pageLoading = 0;
        },
        err => {

        }
      )
  }

  deleteCategory(id) {
    this.sweetalert.delete().then(res => {
      this.categoryService.deleteCategory(id)
        .subscribe((res) => {
          this.sweetalert.centerSuccessTimer('Category successfully deleted.');
          this.getCategoryList();
        }, (err) => {

        })
    }).catch(function (error) {
      // console.log(error);
    });
  }

  initDatatable() {
    $('#bootstrap-data-table').DataTable();
  }

}
