import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import '../../../assets/js/lib/chart-js/Chart.bundle.js';
import '../../../assets/js/lib/flot-chart/jquery.flot.js';
import '../../../assets/js/lib/flot-chart/jquery.flot.pie.js';
import '../../../assets/js/lib/flot-chart/jquery.flot.spline.js';
import showChart from '../../../assets/js/dashboard.js';
import { DashboardService } from './service/dashboard.service.js';
declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  public allData: any;
  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getCustomerList();
  }

  getCustomerList() {
    this.dashboardService.getAllData()
      .subscribe(
        res => {
          this.allData = res.data;

          // showChart();
        },
        err => {

        }
      )
  }

}
