import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-service-edit',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.css']
})
export class ServiceEditComponent implements OnInit {

  public category = {
    name: '',
    shortDesc: '',
    providedServiceName: '',
    providedServiceDesc: '',
    serviceFeature1: '',
    serviceFeature1Desc: '',
    serviceFeature2: '',
    serviceFeature2Desc: '',
    serviceFeature3: '',
    serviceFeature3Desc: '',
    clientServiceTitle: '',
    clientServiceDesc: ''
  }
  public error: String;
  public pageLoading = 1;
  public Editor = ClassicEditor;

  constructor(private categoryService: ProjectService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let id = this.activatedRoute.snapshot.params.id;
    this.categoryService.getCategoryById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.category.name = res.data.name;
        this.category.shortDesc = res.data.shortDesc,
          this.category.providedServiceName = res.data.providedServiceName,
          this.category.providedServiceDesc = res.data.providedServiceDesc,
          this.category.serviceFeature1 = res.data.serviceFeature1,
          this.category.serviceFeature1Desc = res.data.serviceFeature1Desc,
          this.category.serviceFeature2 = res.data.serviceFeature2,
          this.category.serviceFeature2Desc = res.data.serviceFeature2Desc,
          this.category.serviceFeature3 = res.data.serviceFeature3,
          this.category.serviceFeature3Desc = res.data.serviceFeature3Desc,
          this.category.clientServiceTitle = res.data.clientServiceTitle,
          this.category.clientServiceDesc = res.data.clientServiceDesc
      })
  }

  updateCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    let id = this.activatedRoute.snapshot.params.id;
    var fd = new FormData();
    fd.append('name', this.category.name);
    fd.append('shortDesc', this.category.shortDesc);
    fd.append('uploadedBackgroundImg', this.category['uploadedBackgroundImg']);
    fd.append('providedServiceName', this.category.providedServiceName);
    fd.append('providedServiceDesc', this.category.providedServiceDesc);
    fd.append('uploadedProvidedServiceImg', this.category['uploadedProvidedServiceImg']);
    fd.append('serviceFeature1', this.category.serviceFeature1);
    fd.append('serviceFeature1Desc', this.category.serviceFeature1Desc);
    fd.append('serviceFeature2', this.category.serviceFeature2);
    fd.append('serviceFeature2Desc', this.category.serviceFeature2Desc);
    fd.append('serviceFeature3', this.category.serviceFeature3);
    fd.append('serviceFeature3Desc', this.category.serviceFeature3Desc);
    fd.append('uploadedServiceFeatureBackgroundImg', this.category['uploadedServiceFeatureBackgroundImg']);
    fd.append('clientServiceTitle', this.category.clientServiceTitle);
    fd.append('clientServiceDesc', this.category.clientServiceDesc);
    fd.append('uploadedClientServiceBackgroundImg', this.category['uploadedClientServiceBackgroundImg']);
    fd.append('uploadedImage', this.category['uploadedImage']);
    fd.append('uploadedImage2', this.category['uploadedImage2']);
    fd.append('uploadedImage3', this.category['uploadedImage3']);
    fd.append('uploadedImage4', this.category['uploadedImage4']);
    this.categoryService.updateCategory(id, fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Category updated successfully.')
        this.router.navigate(['/services']);
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

}
