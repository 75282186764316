import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/home-slider.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent implements OnInit {

  public data = {
    title: '',
    categoryUrlSlug: '',
    image: ''
  }
  public error: String;
  public pageLoading = 1;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    let id = this.activatedRoute.snapshot.params.id;
    this.homeSliderService.getDataById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.data.title = res.data.title;
        this.data.categoryUrlSlug = res.data.categoryUrlSlug;
      })
  }

  update(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    let id = this.activatedRoute.snapshot.params.id;
    var fd = new FormData();
    fd.append('title', this.data.title);
    fd.append('categoryUrlSlug', this.data.categoryUrlSlug);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.update(id, fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Client updated successfully.')
        this.router.navigate(['/clients']);
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
