import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MaxFileSizeDirective } from '../directives/max-file-size.directive';

import { ModulesComponent } from './modules.component';
import { ModulesRoutingModule } from './modules-routing.module';
import { HeaderComponent } from './layouts/header/header.component';
import { SideBarComponent } from './layouts/side-bar/side-bar.component';
import { FooterComponent } from './layouts/footer/footer.component';

import { QuoteListComponent } from './quote/quote-list/quote-list.component';
import { AddNewComponent } from './layouts/add-new/add-new.component';
import { PageLoaderComponent } from '../loader/page-loader/page-loader.component';

import { CustomerListComponent } from './customer/customer-list/customer-list.component';

import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderShowComponent } from './order/order-show/order-show.component';

import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ProjectEditComponent } from './project/project-edit/project-edit.component';
import { ProjectShowComponent } from './project/project-show/project-show.component';

import { GalleryImageListComponent } from './galleryImage/galleryImage-list/galleryImage-list.component';
import { GalleryImageCreateComponent } from './galleryImage/galleryImage-create/galleryImage-create.component';
import { GalleryImageEditComponent } from './galleryImage/galleryImage-edit/galleryImage-edit.component';
import { GalleryImageShowComponent } from './galleryImage/galleryImage-show/galleryImage-show.component';

import { GalleryVideoListComponent } from './galleryVideo/galleryVideo-list/galleryVideo-list.component';
import { GalleryVideoCreateComponent } from './galleryVideo/galleryVideo-create/galleryVideo-create.component';
import { GalleryVideoEditComponent } from './galleryVideo/galleryVideo-edit/galleryVideo-edit.component';
import { GalleryVideoShowComponent } from './galleryVideo/galleryVideo-show/galleryVideo-show.component';

import { ServiceListComponent } from './service/service-list/service-list.component';
import { ServiceCreateComponent } from './service/service-create/service-create.component';
import { ServiceEditComponent } from './service/service-edit/service-edit.component';
import { ServiceShowComponent } from './service/service-show/service-show.component';

import { HomeSliderListComponent } from './home-slider/home-slider-list/home-slider-list.component';
import { HomeSliderCreateComponent } from './home-slider/home-slider-create/home-slider-create.component';
import { HomeSliderEditComponent } from './home-slider/home-slider-edit/home-slider-edit.component';
import { HomeSliderShowComponent } from './home-slider/home-slider-show/home-slider-show.component';

import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { BlogCreateComponent } from './blog/blog-create/blog-create.component';
import { BlogEditComponent } from './blog/blog-edit/blog-edit.component';
import { BlogShowComponent } from './blog/blog-show/blog-show.component';

import { ClientListComponent } from './client/client-list/client-list.component';
import { ClientCreateComponent } from './client/client-create/client-create.component';
import { ClientEditComponent } from './client/client-edit/client-edit.component';
import { ClientShowComponent } from './client/client-show/client-show.component';

import { TeamListComponent } from './team/team-list/team-list.component';
import { TeamCreateComponent } from './team/team-create/team-create.component';
import { TeamEditComponent } from './team/team-edit/team-edit.component';
import { TeamShowComponent } from './team/team-show/team-show.component';

import { SettingEditComponent } from './setting/setting-edit/setting-edit.component';

import { CatalogListComponent } from './catalog/catalog-list/catalog-list.component';
import { CatalogCreateComponent } from './catalog/catalog-create/catalog-create.component';
import { CatalogEditComponent } from './catalog/catalog-edit/catalog-edit.component';
import { CatelogShowComponent } from './catalog/catalog-show/catalog-show.component';

@NgModule({
  declarations: [
    ModulesComponent,
    MaxFileSizeDirective,
    HeaderComponent,
    SideBarComponent,
    FooterComponent,
    QuoteListComponent,
    AddNewComponent,
    PageLoaderComponent,
    CustomerListComponent,
    OrderListComponent,
    OrderShowComponent,
    ProjectListComponent,
    ProjectCreateComponent,
    ProjectEditComponent,
    ProjectShowComponent,
    GalleryImageListComponent,
    GalleryImageCreateComponent,
    GalleryImageEditComponent,
    GalleryImageShowComponent,
    GalleryVideoListComponent,
    GalleryVideoCreateComponent,
    GalleryVideoEditComponent,
    GalleryVideoShowComponent,
    ServiceListComponent,
    ServiceCreateComponent,
    ServiceEditComponent,
    ServiceShowComponent,

    HomeSliderCreateComponent,
    HomeSliderEditComponent,
    HomeSliderListComponent,
    HomeSliderShowComponent,

    BlogCreateComponent,
    BlogEditComponent,
    BlogListComponent,
    BlogShowComponent,

    ClientCreateComponent,
    ClientEditComponent,
    ClientListComponent,
    ClientShowComponent,

    TeamCreateComponent,
    TeamEditComponent,
    TeamListComponent,
    TeamShowComponent,

    SettingEditComponent,

    CatalogListComponent,
    CatalogCreateComponent,
    CatalogEditComponent,
    CatelogShowComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ModulesRoutingModule,
    CKEditorModule
  ],
  bootstrap: [ModulesComponent]
})
export class ModulesModule { }
