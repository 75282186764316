<!-- Left Panel -->
<aside id="left-panel" class="left-panel">
    <nav class="navbar navbar-expand-sm navbar-default">
        <div id="main-menu" class="main-menu collapse navbar-collapse">
            <ul class="nav navbar-nav">
                <li class="" routerLinkActive="active">
                    <a routerLink="/dashboard"><i class="menu-icon fa fa-laptop"></i>Dashboard </a>
                </li>
                <li class="menu-title">Quotes</li><!-- /.menu-title -->
                <li routerLinkActive="active">
                    <a routerLink="/quotes"> <i class="menu-icon fa fa-cogs"></i>Quotes</a>
                </li>
                <!-- <li routerLinkActive="active">
                    <a routerLink="/customers"> <i class="menu-icon fa fa-cogs"></i>Customers</a>
                </li> -->
                <!-- <li routerLinkActive="active">
                    <a routerLink="/orders"> <i class="menu-icon fa fa-cogs"></i>Orders</a>
                </li> -->

                <!-- <li routerLinkActive="active">
                    <a routerLink="/projects"> <i class="menu-icon fa fa-cogs"></i>Projects</a>
                </li> -->

                <li routerLinkActive="active">
                    <a routerLink="/gallery-videos"> <i class="menu-icon fa fa-cogs"></i>Video Gallery</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/gallery-images"> <i class="menu-icon fa fa-cogs"></i>Projects</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/services"> <i class="menu-icon fa fa-cogs"></i>Services</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/home-sliders"> <i class="menu-icon fa fa-cogs"></i>Slider</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/blogs"> <i class="menu-icon fa fa-cogs"></i>Blogs</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/clients"> <i class="menu-icon fa fa-cogs"></i>Clients</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/teams"> <i class="menu-icon fa fa-cogs"></i>Teams</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/catalogs"> <i class="menu-icon fa fa-cogs"></i>Catalogs</a>
                </li>

                <li routerLinkActive="active">
                    <a routerLink="/setting/edit/1"> <i class="menu-icon fa fa-cogs"></i>Setting</a>
                </li>

            </ul>
        </div><!-- /.navbar-collapse -->
    </nav>
</aside><!-- /#left-panel -->
<!-- Left Panel -->