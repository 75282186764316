<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Services</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard" routerLinkActive="active">Dashboard</a></li>
                            <li><a routerLink="/services" routerLinkActive="active">Services</a></li>
                            <li class="active">List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="animated fadeIn">
        <div class="row">

            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <strong class="card-title">Service List</strong>
                    </div>
                    <div class="card-body">
                        <table id="bootstrap-data-table" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Serial</th>
                                    <th>Name</th>
                                    <!-- <th>Type</th> -->
                                    <!-- <th>Image</th> -->
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let category of categories; index as i;last as last">
                                    <td>{{i+1}}</td>
                                    <td>{{category.name}}</td>
                                    <!-- <td>{{category.type}}</td> -->
                                    <!-- <td><img *ngIf="category.image" [src]="domain+category.image"></td> -->
                                    <td>
                                        {{last ?
                                        initDatatable() : ''}}
                                        <!-- <a [routerLink]="['show',category.id]" class="btn btn-success mx-1"><i
                                                class="fa fa-eye"></i></a> -->
                                        <a [routerLink]="['edit',category.id]" class="btn btn-warning mx-1"><i
                                                class="fa fa-pencil"></i></a>
                                        <button class="btn btn-danger mx-1" (click)="deleteCategory(category.id)"><i
                                                class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div><!-- .animated -->
</div><!-- .content -->

<app-add-new [link]="'create'"></app-add-new>

<app-page-loader *ngIf="pageLoading"></app-page-loader>