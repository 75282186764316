<div class="bg-dark vh-100">
    <div class="sufee-login d-flex align-content-center flex-wrap">
        <div class="container">
            <div class="login-content" style="margin: 20vh auto;">
                <div class="login-logo">
                    <a href="index.html">
                        <!-- <img class="align-content" src="assets/images/logo.png" alt=""> -->
                    </a>
                </div>
                <div class="login-form">
                    <div class="text-center" *ngIf="error">
                        <span class="text-danger">{{error}}</span>
                    </div>
                    <form #loginForm="ngForm">
                        <h1 class="text-center mb-4">
                            <b>Login</b>
                        </h1>
                        <div class="form-group">
                            <label>Email address</label>
                            <input type="email" required class="form-control" id="email" placeholder=""
                                [(ngModel)]="loginUserData.email" name="email" #email="ngModel"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$">
                            <div *ngIf="email.errors && (email.touched||loginForm.submitted)">
                                <div class="text-danger" *ngIf="email.errors.required">Email filed is required</div>
                                <div class="text-danger" *ngIf="email.errors.pattern">Please enter valid email</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" id="password" placeholder=""
                                [(ngModel)]="loginUserData.password" name="password" #password="ngModel" required
                                pattern=".{6,}">
                            <div *ngIf="password.errors && (password.touched||loginForm.submitted)">
                                <div class="text-danger" *ngIf="password.errors.required">password filed is required
                                </div>
                                <div class="text-danger" *ngIf="password.errors.pattern">password must be minimum 6
                                    digit</div>
                            </div>
                        </div>
                        <!-- <div class="checkbox">
                            <label>
                                <input type="checkbox"> Remember Me
                            </label>
                            <label class="pull-right">
                                <a href="#">Forgotten Password?</a>
                            </label>

                        </div> -->
                        <button type="submit" class="btn btn-success btn-flat m-b-30 m-t-30 mb-4"
                            [disabled]="!loginForm.form.valid" (click)="loginUser(loginForm.form.valid)">Sign
                            in</button>

                        <!-- <div class="register-link m-t-15 text-center">
                            <p>Don't have account ? <a href="#"> Sign Up Here</a></p>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>