import { Directive, ElementRef, Attribute } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, FormControl } from '@angular/forms';

@Directive({
  selector: '[appMaxFileSize]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: MaxFileSizeDirective,
      multi: true
    }
  ]
})
export class MaxFileSizeDirective implements Validator {

  validator: ValidatorFn;
  constructor(private eleRef: ElementRef, @Attribute('appMaxFileSize') public maxFileSize: number) {
    this.validator = this.validFileSize();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

  validFileSize(): ValidatorFn {

    return (control: FormControl) => {
      if (this.eleRef.nativeElement.files[0] && this.eleRef.nativeElement.files[0].size > this.maxFileSize) {
        return {
          validFileSize: { valid: false }
        };
      } else {
        return null;
      }
    };
  }
}
