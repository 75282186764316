import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModulesComponent } from './modules.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { QuoteListComponent } from './quote/quote-list/quote-list.component';

import { CustomerListComponent } from './customer/customer-list/customer-list.component';

import { OrderListComponent } from './order/order-list/order-list.component';
import { OrderShowComponent } from './order/order-show/order-show.component';


import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectCreateComponent } from './project/project-create/project-create.component';
import { ProjectEditComponent } from './project/project-edit/project-edit.component';
import { ProjectShowComponent } from './project/project-show/project-show.component';

import { GalleryImageListComponent } from './galleryImage/galleryImage-list/galleryImage-list.component';
import { GalleryImageCreateComponent } from './galleryImage/galleryImage-create/galleryImage-create.component';
import { GalleryImageEditComponent } from './galleryImage/galleryImage-edit/galleryImage-edit.component';
import { GalleryImageShowComponent } from './galleryImage/galleryImage-show/galleryImage-show.component';

import { GalleryVideoListComponent } from './galleryVideo/galleryVideo-list/galleryVideo-list.component';
import { GalleryVideoCreateComponent } from './galleryVideo/galleryVideo-create/galleryVideo-create.component';
import { GalleryVideoEditComponent } from './galleryVideo/galleryVideo-edit/galleryVideo-edit.component';
import { GalleryVideoShowComponent } from './galleryVideo/galleryVideo-show/galleryVideo-show.component';

import { ServiceListComponent } from './service/service-list/service-list.component';
import { ServiceCreateComponent } from './service/service-create/service-create.component';
import { ServiceEditComponent } from './service/service-edit/service-edit.component';
import { ServiceShowComponent } from './service/service-show/service-show.component';

import { HomeSliderListComponent } from './home-slider/home-slider-list/home-slider-list.component';
import { HomeSliderCreateComponent } from './home-slider/home-slider-create/home-slider-create.component';
import { HomeSliderEditComponent } from './home-slider/home-slider-edit/home-slider-edit.component';
import { HomeSliderShowComponent } from './home-slider/home-slider-show/home-slider-show.component';

import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { BlogCreateComponent } from './blog/blog-create/blog-create.component';
import { BlogEditComponent } from './blog/blog-edit/blog-edit.component';
import { BlogShowComponent } from './blog/blog-show/blog-show.component';

import { ClientListComponent } from './client/client-list/client-list.component';
import { ClientCreateComponent } from './client/client-create/client-create.component';
import { ClientEditComponent } from './client/client-edit/client-edit.component';
import { ClientShowComponent } from './client/client-show/client-show.component';

import { TeamListComponent } from './team/team-list/team-list.component';
import { TeamCreateComponent } from './team/team-create/team-create.component';
import { TeamEditComponent } from './team/team-edit/team-edit.component';
import { TeamShowComponent } from './team/team-show/team-show.component';

import { SettingEditComponent } from './setting/setting-edit/setting-edit.component';

import { CatalogListComponent } from './catalog/catalog-list/catalog-list.component';
import { CatalogCreateComponent } from './catalog/catalog-create/catalog-create.component';
import { CatalogEditComponent } from './catalog/catalog-edit/catalog-edit.component';
import { CatelogShowComponent } from './catalog/catalog-show/catalog-show.component';


const routes: Routes = [
    {
        path: '',
        component: ModulesComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'quotes',
                children: [
                    {
                        path: '',
                        component: QuoteListComponent
                    }
                ]
            },
            {
                path: 'customers',
                children: [
                    {
                        path: '',
                        component: CustomerListComponent
                    }
                ]
            },
            {
                path: 'orders',
                children: [
                    {
                        path: '',
                        component: OrderListComponent
                    },
                    {
                        path: 'show/:id',
                        component: OrderShowComponent
                    }
                ]
            },
            {
                path: 'projects',
                children: [
                    {
                        path: '',
                        component: ProjectListComponent
                    },
                    {
                        path: 'create',
                        component: ProjectCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: ProjectEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: ProjectShowComponent
                    }
                ]
            },
            {
                path: 'gallery-images',
                children: [
                    {
                        path: '',
                        component: GalleryImageListComponent
                    },
                    {
                        path: 'create',
                        component: GalleryImageCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: GalleryImageEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: GalleryImageShowComponent
                    }
                ]
            },
            {
                path: 'gallery-videos',
                children: [
                    {
                        path: '',
                        component: GalleryVideoListComponent
                    },
                    {
                        path: 'create',
                        component: GalleryVideoCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: GalleryVideoEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: GalleryVideoShowComponent
                    }
                ]
            },
            {
                path: 'services',
                children: [
                    {
                        path: '',
                        component: ServiceListComponent
                    },
                    {
                        path: 'create',
                        component: ServiceCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: ServiceEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: ServiceShowComponent
                    }
                ]
            },
            {
                path: 'home-sliders',
                children: [
                    {
                        path: '',
                        component: HomeSliderListComponent
                    },
                    {
                        path: 'create',
                        component: HomeSliderCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: HomeSliderEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: HomeSliderShowComponent
                    }
                ]
            },
            {
                path: 'blogs',
                children: [
                    {
                        path: '',
                        component: BlogListComponent
                    },
                    {
                        path: 'create',
                        component: BlogCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: BlogEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: BlogShowComponent
                    }
                ]
            },
            {
                path: 'teams',
                children: [
                    {
                        path: '',
                        component: TeamListComponent
                    },
                    {
                        path: 'create',
                        component: TeamCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: TeamEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: TeamShowComponent
                    }
                ]
            },
            {
                path: 'clients',
                children: [
                    {
                        path: '',
                        component: ClientListComponent
                    },
                    {
                        path: 'create',
                        component: ClientCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: ClientEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: ClientShowComponent
                    }
                ]
            },
            {
                path: 'setting',
                children: [
                    {
                        path: 'edit/:id',
                        component: SettingEditComponent
                    }
                ]
            },
            {
                path: 'catalogs',
                children: [
                    {
                        path: '',
                        component: CatalogListComponent
                    },
                    {
                        path: 'create',
                        component: CatalogCreateComponent
                    },
                    {
                        path: 'edit/:id',
                        component: CatalogEditComponent
                    },
                    {
                        path: 'show/:id',
                        component: CatelogShowComponent
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ModulesRoutingModule { }