<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Blog</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/blogs">Blog</a></li>
                            <li class="active">View</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content">
    <div class="animated fadeIn">


        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <strong>Blog View</strong>
                    </div>
                    <div class="card-body card-block">
                        <form action="#" method="post" enctype="multipart/form-data" class="form-horizontal">

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Title</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.title}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Description</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.description}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Image</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">
                                        <img *ngIf="data.image" class="banner-view" [src]="domain+data.image" />
                                    </p>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="card-footer">
                    </div>
                </div>
            </div>


        </div>


    </div><!-- .animated -->
</div><!-- .content -->

<app-page-loader *ngIf="pageLoading"></app-page-loader>