import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/blog.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-blog-edit',
  templateUrl: './blog-edit.component.html',
  styleUrls: ['./blog-edit.component.css']
})
export class BlogEditComponent implements OnInit {

  public data = {
    title: '',
    short_description: '',
    description: '<p>Hello, world!</p>',
    image: ''
  }
  public error: String;
  public pageLoading = 1;
  public Editor = ClassicEditor;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    let id = this.activatedRoute.snapshot.params.id;
    this.homeSliderService.getDataById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.data.title = res.data.title;
        this.data.short_description = res.data.short_description;
        this.data.description = res.data.description;
      })
  }

  update(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    // this.pageLoading = 1;
    let id = this.activatedRoute.snapshot.params.id;
    var fd = new FormData();
    fd.append('title', this.data.title);
    fd.append('short_description', this.data.short_description);
    fd.append('description', this.data.description);
    fd.append('uploadedImage', this.data['uploadedImage']);
    this.homeSliderService.update(id, fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Slider updated successfully.')
        this.router.navigate(['/blogs']);
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
