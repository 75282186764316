import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/home-slider.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.css']
})
export class TeamEditComponent implements OnInit {

  public data = {
    name: '',
    designation: '',
    details: '',
    image: ''
  }
  public error: String;
  public pageLoading = 1;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    let id = this.activatedRoute.snapshot.params.id;
    this.homeSliderService.getDataById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.data.name = res.data.name;
        this.data.designation = res.data.designation;
        this.data.details = res.data.details;
      })
  }

  update(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    let id = this.activatedRoute.snapshot.params.id;
    var fd = new FormData();
    fd.append('name', this.data.name);
    fd.append('designation', this.data.designation);
    fd.append('details', this.data.details);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.update(id, fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Team updated successfully.')
        this.router.navigate(['/teams']);
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
