import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setting-edit',
  templateUrl: './setting-edit.component.html',
  styleUrls: ['./setting-edit.component.css']
})
export class SettingEditComponent implements OnInit {

  public category = {
    address: '',
    email: '',
    phone: '',
    mapSrc: '',
    perfectDesign: '',
    carefullyPlanned: '',
    smartlyExecute: '',
    footerText: '',
    ourStory:''
  }
  public error: String;
  public pageLoading = 1;

  constructor(private categoryService: ProjectService, private router: Router, private activatedRoute: ActivatedRoute, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory() {
    let id = this.activatedRoute.snapshot.params.id;
    this.categoryService.getCategoryById(id)
      .subscribe((res: any) => {
        this.pageLoading = 0;
        this.category.address = res.data.address;
        this.category.email = res.data.email;
        this.category.phone = res.data.phone;
        this.category.perfectDesign = res.data.perfectDesign;
        this.category.carefullyPlanned = res.data.carefullyPlanned;
        this.category.smartlyExecute = res.data.smartlyExecute;
        this.category.footerText = res.data.footerText;
        this.category.ourStory = res.data.ourStory;
        this.category.mapSrc = `<iframe src="${res.data.mapSrc}" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
      })
  }

  updateCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    this.category.mapSrc = this.category.mapSrc.split('src=').map(el => el.split('"')).reduce((acc, curr) => acc.concat(curr))[2]
    let id = this.activatedRoute.snapshot.params.id;


    this.categoryService.updateCategory(id, this.category)
      .subscribe((res) => {
        this.getCategory();
        this.sweetalert.centerSuccessTimer('Setting updated successfully.')
        this.router.navigate(['/setting/edit/1']);
        this.pageLoading = 0;
      }, (err => {
        this.pageLoading = 0;
        this.error = err.error.message;
      }))
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

}
