import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import domain from '../../data/domain';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }

  getAllCategory() {
    return this.http.get<any>(domain + "services");
  }

  storeCategory(data) {
    return this.http.post<any>(domain + "services", data);
  }

  getCategoryById(id) {
    return this.http.get(domain + 'services/' + id);
  }

  updateCategory(id, data) {
    return this.http.put<any>(domain + "services/" + id, data);
  }

  deleteCategory(id) {
    return this.http.delete(domain + 'services/' + id);
  }
}
