import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

@Injectable({
	providedIn: 'root'
})
export class SweetalertService {

	constructor() { }

	centerSuccessTimer(message='Submitted'){
		Swal.fire({
			position: 'center',
			type: 'success',
			title: message,
			showConfirmButton: false,
			timer: 1500
		})
	}

	delete(){
		return new Promise(function(resolve, reject){
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					resolve();
				}
				else{
					reject()
				}
			})
		})
	}

	toastSuccess(message='Success'){
		let Toast = Swal.mixin({
			toast: true,
			position: 'top-end',
			showConfirmButton: false,
			timer: 3000
		});
		Toast.fire({
			type: 'success',
			title: message
		})
	}
}
