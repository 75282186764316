<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Order</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/orders">Order</a></li>
                            <li class="active">View</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content">
    <div class="animated fadeIn">


        <div class="row">

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <strong>Order View</strong>
                    </div>
                    <div class="card-body card-block">
                        <form action="#" method="post" enctype="multipart/form-data" class="form-horizontal">
                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Customer name</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.name}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Order Id</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.orderId}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Delivery Charge</label>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">
                                        {{data.deliveryCharge}}
                                    </p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Product Price</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">
                                        {{data.productTotalPrice}}
                                    </p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Product Total Price</label>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">
                                        {{data.productTotalPrice}}
                                    </p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Totall Price</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.totallPrice}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Amount Paid</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.amountPaid}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Email</label></div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.email}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Phone</label>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.phone}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Address</label>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.address}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label class=" form-control-label">Num Of Products</label>
                                </div>
                                <div class="col-12 col-md-9">
                                    <p class="form-control-static">{{data.orderProducts.length}}</p>
                                </div>
                            </div>

                            <div class="row form-group">
                                <div class="col col-md-3"><label for="productName"
                                        class="form-control-label">Products</label></div>
                                <div class="col-12 col-md-6">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Product Name</th>
                                                <th scope="col">Quantity</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let orderProduct of data.orderProducts; let i = index">
                                                <th scope="row">{{i+1}}</th>
                                                <td>{{orderProduct.title}}</td>
                                                <td>
                                                    {{orderProduct.quantity }}
                                                </td>
                                                <td>{{orderProduct.price}}Tk</td>
                                            </tr>
                                            <tr class="border-top font-weight-bold">
                                                <td></td>
                                                <td>Total Price</td>
                                                <td></td>
                                                <td>{{calSelectedProductsSum()}}Tk</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="card-footer">
                    </div>
                </div>
            </div>


        </div>


    </div><!-- .animated -->
</div><!-- .content -->

<app-page-loader *ngIf="pageLoading"></app-page-loader>