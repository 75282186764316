<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Order</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/stocks">Order</a></li>
                            <li class="active">List</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="animated fadeIn">
        <div class="row">

            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <strong class="card-title">Order List</strong>
                    </div>
                    <div class="card-body">
                        <table id="bootstrap-data-table" class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Serial</th>
                                    <th>Order Id</th>
                                    <th>Name</th>
                                    <th>Total Price</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Address</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orders; index as i;last as last">
                                    <td>{{i+1}}</td>
                                    <td>{{order.orderId}}</td>
                                    <td>{{order.name}}</td>
                                    <td>{{order.totallPrice}}</td>
                                    <td>{{order.email}}</td>
                                    <td>{{order.deliveryPhone}} </td>
                                    <td>{{order.city}}</td>
                                    <td>{{order.state}}</td>
                                    <td>{{order.deliveryAddress}} {{last ? initDatatable() : ''}}</td>
                                    <td>
                                        <a [routerLink]="['show',order.id]" class="btn btn-success mx-1"><i
                                                class="fa fa-eye"></i></a>
                                        <button class="btn btn-danger mx-1" (click)="deleteStock(order.id)"><i
                                                class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


        </div>
    </div><!-- .animated -->
</div><!-- .content -->


<app-page-loader *ngIf="pageLoading"></app-page-loader>