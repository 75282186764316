import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/blog.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-blog-create',
  templateUrl: './blog-create.component.html',
  styleUrls: ['./blog-create.component.css']
})
export class BlogCreateComponent implements OnInit {

  public data = {
    title: '',
    short_description: '',
    description: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;
  public Editor = ClassicEditor;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  store(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('title', this.data.title);
    fd.append('short_description', this.data.short_description);
    fd.append('description', this.data.description);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.store(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Blog created successfully.')
        this.router.navigate(['/blogs'])
      }, (err) => {
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.data[event.target.name] = event.target.files[0];
  }

}
