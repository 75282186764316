<div class="clearfix"></div>

<footer class="site-footer">
    <div class="footer-inner bg-white">
        <div class="row">
            <div class="col-sm-6">
                <!-- Copyright &copy; 2020 <a href="http://kathbirali.com.bd">Kathbirali</a> -->
            </div>
            <div class="col-sm-6 text-right">
                <!-- Designed with Love by <a href="http://riponbd.me/">Ripon Ghosh</a> -->
            </div>
        </div>
    </div>
</footer>