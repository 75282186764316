import { Component, OnInit } from '@angular/core';
import { HomeSliderService } from '../service/catalog.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-catalog-create',
  templateUrl: './catalog-create.component.html',
  styleUrls: ['./catalog-create.component.css']
})
export class CatalogCreateComponent implements OnInit {

  public data: any = {
    title: '',
    categoryUrlSlug: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;

  constructor(private homeSliderService: HomeSliderService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  store(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    console.log('form')
    // return;
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('title', this.data.title);
    fd.append('categoryUrlSlug', this.data.categoryUrlSlug);
    fd.append('uploadedImage', this.data['uploadedImage']);

    this.homeSliderService.store(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Slider created successfully.')
        this.router.navigate(['/catalogs'])
      }, (err) => {
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    // this.data.image = event.target.files[0]
    // if (event.target.files[0].size > 100) {
    //   this.error = 'Something wrong with your input data';
    //   return;
    // }
    // console.log(event)
    this.data[event.target.name] = event.target.files[0];
  }

  validateImageSize(name, size) {
    if (this.data[name] && this.data[name].size > size) {
      return false;
    }
    return true;
  }

}
