import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import domain from '../../data/domain';

@Injectable({
  providedIn: 'root'
})
export class HomeSliderService {

  constructor(private http: HttpClient) { }

  getAllData() {
    return this.http.get<any>(domain + "catalogs");
  }

  store(data) {
    return this.http.post<any>(domain + "catalogs", data);
  }

  getDataById(id) {
    return this.http.get(domain + 'catalogs/' + id);
  }

  update(id, data) {
    return this.http.put<any>(domain + "catalogs/" + id, data);
  }

  delete(id) {
    return this.http.delete(domain + 'catalogs/' + id);
  }
}
