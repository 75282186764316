<div class="breadcrumbs">
    <div class="breadcrumbs-inner">
        <div class="row m-0">
            <div class="col-sm-4">
                <div class="page-header float-left">
                    <div class="page-title">
                        <h1>Service</h1>
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="page-header float-right">
                    <div class="page-title">
                        <ol class="breadcrumb text-right">
                            <li><a routerLink="/dashboard">Dashboard</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li class="active">Create</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="content">
    <div class="animated fadeIn">


        <form #categoryForm="ngForm">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header">
                            <strong>Service Create</strong> Forms
                        </div>
                        <div class="card-body card-block">
                            <div class="form-horizontal">

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="email-input"
                                            class=" form-control-label">Service Name</label></div>
                                    <div class="col-12 col-md-6">
                                        <input type="text" class="form-control" id="name" placeholder=""
                                            [(ngModel)]="category.name" name="name" #name="ngModel" required>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(name.untouched&&!categoryForm.submitted)||name.valid">Please
                                            enter Service name</small>
                                    </div>
                                </div>
                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="email-input"
                                            class=" form-control-label">Service short Description</label></div>
                                    <div class="col-12 col-md-6">
                                        <!-- <input type="text" class="form-control" id="shortDesc" placeholder=""
                                            [(ngModel)]="category.shortDesc" name="shortDesc" #shortDesc="ngModel"
                                            required> -->
                                        <ckeditor [editor]="Editor" id="shortDesc" placeholder=""
                                            [(ngModel)]="category.shortDesc" name="shortDesc" #shortDesc="ngModel"
                                            required> </ckeditor>
                                        <small class="form-text text-danger error"
                                            [class.d-none]="(shortDesc.untouched&&!categoryForm.submitted)||shortDesc.valid">Please
                                            enter Service shortDesc</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input"
                                            class=" form-control-label">Service
                                            Background
                                            Image(1800*1044)</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="uploadedBackgroundImg" name="uploadedBackgroundImg"
                                            class="form-control-file" (change)="imageSelect($event)"
                                            appMaxFileSize="600000" ngModel #uploadedBackgroundImg="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedBackgroundImg.errors||!uploadedBackgroundImg.errors.validFileSize">Please
                                            select image less than 600 KB</small>
                                    </div>
                                </div>
                                <div class="row col-11 mb-4">
                                    <div class="col-12 mb-4 mt-3">
                                        <h5 class="font-weight-bold">Provided Service</h5>
                                    </div>

                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="email-input"
                                                    class=" form-control-label">Provided Service Name</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="providedServiceName"
                                                    placeholder="" [(ngModel)]="category.providedServiceName"
                                                    name="providedServiceName" #providedServiceName="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(providedServiceName.untouched&&!categoryForm.submitted)||providedServiceName.valid">Please
                                                    enter Provided Service name</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col col-md-6 "><label for="file-input"
                                                    class=" form-control-label pr-0 pl-4">Provided Service
                                                    Image(730*730)</label></div>
                                            <div class="col-12 col-md-6">
                                                <input type="file" id="providedServiceImg"
                                                    name="uploadedProvidedServiceImg" class="form-control-file"
                                                    (change)="imageSelect($event)" appMaxFileSize="600000" ngModel
                                                    #uploadedProvidedServiceImg="ngModel">
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="!uploadedProvidedServiceImg.errors||!uploadedProvidedServiceImg.errors.validFileSize">Please
                                                    select image less than 600 KB</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="providedServiceDesc"
                                                    class=" form-control-label">Provided Service Desc</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="providedServiceDesc"
                                                    placeholder="" [(ngModel)]="category.providedServiceDesc"
                                                    name="providedServiceDesc" #providedServiceDesc="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(providedServiceDesc.untouched&&!categoryForm.submitted)||providedServiceDesc.valid">Please
                                                    enter Provided Service Description</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="row col-11 mb-4">
                                    <div class="col-12 mb-4 mt-3">
                                        <h5 class="font-weight-bold">Service Feature</h5>
                                    </div>

                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature1"
                                                    class=" form-control-label">Service Feature 1</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature1"
                                                    placeholder="" [(ngModel)]="category.serviceFeature1"
                                                    name="serviceFeature1" #serviceFeature1="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature1.untouched&&!categoryForm.submitted)||serviceFeature1.valid">Please
                                                    enter Service Feature</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature1Desc"
                                                    class=" form-control-label">Service Feature 1 Desc</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature1Desc"
                                                    placeholder="" [(ngModel)]="category.serviceFeature1Desc"
                                                    name="serviceFeature1Desc" #serviceFeature1Desc="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature1Desc.untouched&&!categoryForm.submitted)||serviceFeature1Desc.valid">Please
                                                    enter Provided Service Description</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature2"
                                                    class=" form-control-label">Service Feature 2</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature2"
                                                    placeholder="" [(ngModel)]="category.serviceFeature2"
                                                    name="serviceFeature2" #serviceFeature2="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature2.untouched&&!categoryForm.submitted)||serviceFeature2.valid">Please
                                                    enter Service Feature</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature2Desc"
                                                    class=" form-control-label">Service Feature 2 Desc</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature2Desc"
                                                    placeholder="" [(ngModel)]="category.serviceFeature2Desc"
                                                    name="serviceFeature2Desc" #serviceFeature2Desc="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature2Desc.untouched&&!categoryForm.submitted)||serviceFeature2Desc.valid">Please
                                                    enter Provided Service Description</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature3"
                                                    class=" form-control-label">Service Feature 3</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature3"
                                                    placeholder="" [(ngModel)]="category.serviceFeature3"
                                                    name="serviceFeature3" #serviceFeature3="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature3.untouched&&!categoryForm.submitted)||serviceFeature3.valid">Please
                                                    enter Service Feature</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="serviceFeature3Desc"
                                                    class=" form-control-label">Service Feature 3 Desc</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="serviceFeature3Desc"
                                                    placeholder="" [(ngModel)]="category.serviceFeature3Desc"
                                                    name="serviceFeature3Desc" #serviceFeature3Desc="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(serviceFeature3Desc.untouched&&!categoryForm.submitted)||serviceFeature3Desc.valid">Please
                                                    enter Provided Service Description</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col col-md-6 "><label for="file-input"
                                                    class=" form-control-label pr-0 pl-4">Feature
                                                    Background(2000*1288)</label></div>
                                            <div class="col-12 col-md-6">
                                                <input type="file" id="serviceFeatureBackgroundImg"
                                                    name="uploadedServiceFeatureBackgroundImg" class="form-control-file"
                                                    (change)="imageSelect($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row col-11 mb-4">
                                    <div class="col-12 mb-4 mt-3">
                                        <h5 class="font-weight-bold">Client Service</h5>
                                    </div>

                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="clientServiceTitle"
                                                    class=" form-control-label">Client Service Title</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="clientServiceTitle"
                                                    placeholder="" [(ngModel)]="category.clientServiceTitle"
                                                    name="clientServiceTitle" #clientServiceTitle="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(clientServiceTitle.untouched&&!categoryForm.submitted)||clientServiceTitle.valid">Please
                                                    enter Provided Service name</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col col-md-6 "><label for="clientServiceBackgroundImg"
                                                    class=" form-control-label pr-0 pl-4">Client Service
                                                    Background(730*730)</label></div>
                                            <div class="col-12 col-md-6">
                                                <input type="file" id="clientServiceBackgroundImg"
                                                    name="uploadedClientServiceBackgroundImg" class="form-control-file"
                                                    (change)="imageSelect($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row form-group">
                                            <div class="col-md-4"><label for="clientServiceDesc"
                                                    class=" form-control-label">Client Service Desc</label></div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" id="clientServiceDesc"
                                                    placeholder="" [(ngModel)]="category.clientServiceDesc"
                                                    name="clientServiceDesc" #clientServiceDesc="ngModel" required>
                                                <small class="form-text text-danger error"
                                                    [class.d-none]="(clientServiceDesc.untouched&&!categoryForm.submitted)||clientServiceDesc.valid">Please
                                                    enter Provided Service Description</small>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input" class=" form-control-label">File
                                            Image(550*500)</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="image" name="uploadedImage" class="form-control-file"
                                            (change)="imageSelect($event)" appMaxFileSize="600000" ngModel
                                            #uploadedImage="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedImage.errors||!uploadedImage.errors.validFileSize">Please
                                            select image less than 600 KB</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input" class=" form-control-label">File
                                            Image(550*500)</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="image" name="uploadedImage2" class="form-control-file"
                                            (change)="imageSelect($event)" appMaxFileSize="600000" ngModel
                                            #uploadedImage2="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedImage2.errors||!uploadedImage2.errors.validFileSize">Please
                                            select image less than 600 KB</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input" class=" form-control-label">File
                                            Image(550*500)</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="image" name="uploadedImage3" class="form-control-file"
                                            (change)="imageSelect($event)" appMaxFileSize="600000" ngModel
                                            #uploadedImage3="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedImage3.errors||!uploadedImage3.errors.validFileSize">Please
                                            select image less than 600 KB</small>
                                    </div>
                                </div>

                                <div class="row form-group">
                                    <div class="col col-md-3"><label for="file-input" class=" form-control-label">File
                                            Image(550*500)</label></div>
                                    <div class="col-12 col-md-9">
                                        <input type="file" id="image" name="uploadedImage4" class="form-control-file"
                                            (change)="imageSelect($event)" appMaxFileSize="600000" ngModel
                                            #uploadedImage4="ngModel">
                                        <small class="form-text text-danger error"
                                            [class.d-none]="!uploadedImage4.errors||!uploadedImage4.errors.validFileSize">Please
                                            select image less than 600 KB</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <button type="submit" class="btn btn-primary btn-sm"
                                (click)="storeCategory(categoryForm.form.valid)">
                                <i class="fa fa-dot-circle-o"></i> Submit
                            </button>
                            <button type="reset" class="btn btn-danger btn-sm">
                                <i class="fa fa-ban"></i> Cancel
                            </button>
                        </div>
                    </div>
                </div>

            </div>

        </form>

    </div><!-- .animated -->
</div><!-- .content -->

<app-page-loader *ngIf="pageLoading"></app-page-loader>