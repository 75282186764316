import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../service/project.service';
import { SweetalertService } from '../../../sweetalert/sweetalert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.css']
})
export class ProjectCreateComponent implements OnInit {

  public category = {
    title: '',
    type: '',
    image: ''
  }
  public error: String;
  public pageLoading = 0;

  constructor(private categoryService: ProjectService, private router: Router, private sweetalert: SweetalertService) { }

  ngOnInit(): void {
  }

  storeCategory(status) {
    if (!status) {
      this.error = 'Something wrong with your input data';
      return;
    }
    this.pageLoading = 1;
    var fd = new FormData();
    fd.append('title', this.category.title);
    fd.append('type', this.category.type);
    fd.append('uploadedImage', this.category['uploadedImage']);

    this.categoryService.storeCategory(fd)
      .subscribe((res) => {
        this.sweetalert.centerSuccessTimer('Category created successfully.')
        this.router.navigate(['/projects'])
      }, (err) => {
        console.log(err)
        this.pageLoading = 0;
        this.error = err.error.message;
        ;
      })
  }

  imageSelect(event) {
    this.category[event.target.name] = event.target.files[0];
  }

}
